import validate from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "proclaim-maintenance": () => import("/opt/buildhome/repo/middleware/ProclaimMaintenance.js"),
  authenticated: () => import("/opt/buildhome/repo/middleware/authenticated.js"),
  guest: () => import("/opt/buildhome/repo/middleware/guest.js")
}