import { default as case_45historyp7IwnRYGQqMeta } from "/opt/buildhome/repo/pages/case-history.vue?macro=true";
import { default as _91id_93mTSu5QQ59HMeta } from "/opt/buildhome/repo/pages/documents/[id].vue?macro=true";
import { default as indexPLPY3FW6dqMeta } from "/opt/buildhome/repo/pages/documents/[matter_type]/[group]/index.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as milestonessqCGpgVL6VMeta } from "/opt/buildhome/repo/pages/milestones.vue?macro=true";
import { default as payment_45detailsVcNbteY4BeMeta } from "/opt/buildhome/repo/pages/payment-details.vue?macro=true";
import { default as payment_45otherpPukIAJN0fMeta } from "/opt/buildhome/repo/pages/payment-other.vue?macro=true";
import { default as paymentt9BiBTWFpLMeta } from "/opt/buildhome/repo/pages/payment.vue?macro=true";
import { default as component_45stubGZr1UkTkg3Meta } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubGZr1UkTkg3 } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "case-history",
    path: "/case-history",
    meta: case_45historyp7IwnRYGQqMeta || {},
    component: () => import("/opt/buildhome/repo/pages/case-history.vue")
  },
  {
    name: "documents-id",
    path: "/documents/:id()",
    meta: _91id_93mTSu5QQ59HMeta || {},
    component: () => import("/opt/buildhome/repo/pages/documents/[id].vue")
  },
  {
    name: "documents-matter_type-group",
    path: "/documents/:matter_type()/:group()",
    meta: indexPLPY3FW6dqMeta || {},
    component: () => import("/opt/buildhome/repo/pages/documents/[matter_type]/[group]/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login4dfr6kRqBoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/login.vue")
  },
  {
    name: "milestones",
    path: "/milestones",
    meta: milestonessqCGpgVL6VMeta || {},
    component: () => import("/opt/buildhome/repo/pages/milestones.vue")
  },
  {
    name: "payment-details",
    path: "/payment-details",
    meta: payment_45detailsVcNbteY4BeMeta || {},
    component: () => import("/opt/buildhome/repo/pages/payment-details.vue")
  },
  {
    name: "payment-other",
    path: "/payment-other",
    meta: payment_45otherpPukIAJN0fMeta || {},
    component: () => import("/opt/buildhome/repo/pages/payment-other.vue")
  },
  {
    name: "payment",
    path: "/payment",
    meta: paymentt9BiBTWFpLMeta || {},
    component: () => import("/opt/buildhome/repo/pages/payment.vue")
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/main",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/main/overview",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/main/task-sign/*",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/main/case-history/*",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/main/purchase-docs/*",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/main/sale-docs/*",
    component: component_45stubGZr1UkTkg3
  }
]