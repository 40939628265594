import { defineStore } from 'pinia'
import axiosService from '~/services/axios.service'

export const useConveyancingCaseStore = defineStore({
  id: 'conveyancing-case',
  state: () => ({
    conveyancingCase: {},
    caseSearches: [],
    caseSearch: null,
  }),
  actions: {
    async fetchCaseSearches() {
      return axiosService
        .get(`/case-searches`)
        .then((response) => (this.caseSearches = response))
        .catch((err) => {
          throw err
        })
    },
    async fetchCaseSearch(id) {
      return axiosService
        .get(`/case-searches/${id}`)
        .then((response) => (this.caseSearch = response))
        .catch((err) => {
          throw err
        })
    },
    async createPaymentIntent(id) {
      return axiosService
        .post(`/case-searches/${id}/payments`)
        .then((response) => response)
        .catch((err) => {
          throw err
        })
    },
    async createOtherPaymentIntent(data) {
      return axiosService
        .post(`/other-payments`, data)
        .then((response) => response)
        .catch((err) => {
          throw err
        })
    },
  },
  persist: true,
})
